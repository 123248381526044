<template>
  <div class="pa-4">
    <tp-text-field
      v-model="config.title"
      class="mb-4"
      dense
      hide-details="auto"
      label="Tiêu đề"
      outlined
      placeholder="Nhập nội dung ngắn gọn"
      validate="required"
    ></tp-text-field>

    <tp-autocomplete
      :items="filteredProductItems"
      :search-input.sync="searchKey"
      class="tp-filter-autocomplete mt-4"
      clearable
      item-text="name"
      item-value="id"
      no-data-text="Không có dữ liệu"
      placeholder="Tìm kiếm sản phẩm"
      single-line
      @change="onChange"
    ></tp-autocomplete>

    <draggable-product-items
      v-model="config.ids"
      :base-value="config.items"
    ></draggable-product-items>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import DraggableProductItems from "./DraggableProductItems.vue";
import { createVuexHelpers } from "@/core/composables";
import { debouncedWatch } from "@vueuse/core";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import ProductService from "@/modules/Goods/service/product.service";

const { searchSpecs } = ProductService;

export default defineComponent({
  components: {
    DraggableProductItems
  },

  setup() {
    const { useGetters } = createVuexHelpers();
    const { homeSetting } = useGetters("HOME_SETTING", ["homeSetting"]);

    const config = computed(() => homeSetting.value.OUTSTANDING_PRODUCT);

    const searchKey = ref("");

    const { data: filteredProductItems, fetch } = useFetchData(
      () => searchSpecs(searchKey.value),
      {
        enabled: () => Boolean(searchKey.value),
        transform: response => response.data
      }
    );

    debouncedWatch(searchKey, fetch, { debounce: 300 });

    const onChange = id => {
      const item = filteredProductItems.value.find(item => item.id === id);

      if (item) {
        config.value.ids.push(id);
        config.value.items.push(item);
      }

      searchKey.value = "";
    };

    return {
      config,
      searchKey,
      filteredProductItems,
      onChange
    };
  }
});
</script>

<style></style>
