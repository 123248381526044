<template>
  <div class="pa-4">
    <tp-text-field
      v-model="config.title"
      class="mb-2"
      hide-details="auto"
      label="Tiêu đề"
      placeholder="Nhập tiêu đề ngắn gọn"
      validate="required"
    ></tp-text-field>

    <button-feedback @on-add="onAdd"></button-feedback>

    <div class="mt-6">
      <draggable v-model="config.items" :animation="200" class="row">
        <v-col
          v-for="(item, index) in config.items"
          :key="index"
          cols="12"
          sm="3"
        >
          <v-card class="h-full" flat outlined>
            <v-img :src="getSrc(item.image)" alt=""></v-img>

            <v-card-title>{{ item.name }}</v-card-title>

            <v-card-actions>
              <v-btn color="primary" text @click="onRemove(index)">
                Xoá
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { computed, defineComponent } from "vue";
import { TUploadImage } from "@vt7/upload-image";
import DraggableProductItems from "./DraggableProductItems.vue";
import { createVuexHelpers } from "@/core/composables";
import ButtonFeedback from "./ButtonFeedback.vue";

export default defineComponent({
  components: {
    draggable,
    ButtonFeedback,
    TUploadImage,
    DraggableProductItems
  },

  setup() {
    const { useGetters } = createVuexHelpers();
    const { homeSetting } = useGetters("HOME_SETTING", ["homeSetting"]);

    const config = computed(() => homeSetting.value.FEEDBACK);

    const onAdd = state => {
      config.value.items.push(state);
    };

    const onRemove = index => {
      config.value.items.splice(index, 1);
    };

    return {
      config,
      onAdd,
      onRemove
    };
  },

  data() {
    return {};
  },

  methods: {
    getSrc(image) {
      if (typeof image === "string") {
        return image;
      }

      return URL.createObjectURL(image);
    }
  }
});
</script>

<style></style>
