<template>
  <div>
    <v-btn color="primary" outlined @click="open">
      <v-icon left>mdi-plus</v-icon>
      Chọn danh mục bài viết
    </v-btn>

    <v-dialog v-model="visible" max-width="768px">
      <v-card>
        <v-card-title>
          <div class="font-weight-bold">
            Chọn danh mục bài viết
          </div>

          <v-spacer></v-spacer>

          <v-btn class="mr-3" color="primary" depressed outlined @click="close">
            Thoát
          </v-btn>

          <v-btn color="primary" depressed @click="onAdd">Lưu</v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <div class="pa-4">
          <tp-autocomplete
            v-model="state.id"
            :items="allPostCategories"
            :search-input.sync="searchInput"
            item-text="name"
            item-value="id"
            no-data-text="Không có dữ liệu"
            placeholder="Chọn danh mục bài viết"
          ></tp-autocomplete>

          <div class="mt-6">
            <tp-input-image
              :maxFileSize="500 * 1024"
              :src.sync="state.image"
              allow-compare-image
              allow-compress
              allowFileSize
              allowFileTypeValidation
            ></tp-input-image>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { useVisible } from "@thinkvn/composables/lib/useVisible";
import { TUploadImage } from "@vt7/upload-image";
import { createVuexHelpers } from "@/core/composables";

export default defineComponent({
  components: { TUploadImage },

  setup(_, { emit }) {
    const { visible, open, close } = useVisible();
    const { useGetters } = createVuexHelpers();

    const { allPostCategories } = useGetters("POST_CATEGORY", [
      "allPostCategories"
    ]);

    const searchInput = ref("");
    const state = reactive({
      id: null,
      image: null
    });

    const onAdd = () => {
      emit("on-add", { ...state });

      state.id = null;
      state.image = null;
      close();
    };

    return {
      visible,
      open,
      close,
      state,
      searchInput,
      allPostCategories,
      onAdd
    };
  },

  data() {
    return {
      apiKeys: process.env.VUE_APP_COMPRESS_API_KEYS.split(",")
    };
  }
});
</script>

<style scoped></style>
