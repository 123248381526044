<template>
  <div class="pa-4">
    <v-overlay
      v-show="
        custommizeFolderStatusRequest.value === 'loading-getCustomizeFolders'
      "
      absolute
      opacity="0.5"
    >
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <tp-text-field
      v-model="homeSettingFolder.title"
      class="mb-4"
      dense
      hide-details="auto"
      label="Tiêu đề"
      outlined
      placeholder="Nhập nội dung ngắn gọn"
      validate="required"
    ></tp-text-field>

    <button-select-folder
      v-model="homeSettingFolder.ids"
    ></button-select-folder>

    <draggable-list
      v-model="homeSettingFolder.ids"
      :base-value="customizeFolders"
      is-folder-list
    ></draggable-list>
  </div>
</template>

<script>
import ButtonSelectFolder from "./ButtonSelectFolder.vue";
import DraggableList from "./DraggableList.vue";

export default {
  components: {
    ButtonSelectFolder,
    DraggableList
  },
  computed: {
    customizeFolders() {
      return this.$store.getters["CUSTOMIZE_FOLDER/customizeFolders"];
    },

    custommizeFolderStatusRequest() {
      return this.$store.getters["CUSTOMIZE_FOLDER/statusRequest"];
    },

    homeSettingFolder() {
      const { FOLDER } = this.$store.getters["HOME_SETTING/homeSetting"];
      return FOLDER;
    }
  }
};
</script>

<style></style>
