<template>
  <div>
    <v-btn color="primary" outlined @click="visible = true">
      <v-icon left>mdi-plus</v-icon>
      Chọn bộ sưu tập</v-btn
    >

    <v-dialog v-model="visible" max-width="768px">
      <v-card>
        <v-card-title>
          <div class="font-weight-bold">
            Chọn bộ sưu tập
          </div>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-3"
            depressed
            color="primary"
            outlined
            @click="visible = false"
            >Thoát</v-btn
          >

          <v-btn depressed color="primary" @click="handleSave">Lưu</v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <div class="pa-4">
          <v-text-field
            v-model="search"
            class="mb-3"
            append-icon="mdi-magnify"
            label="Tìm kiếm theo tên folder"
            outlined
            dense
            hide-details
          ></v-text-field>

          <v-data-table
            v-model="selectedFolders"
            checkbox-color="primary"
            fixed-header
            :headers="FOLDER_HEADER"
            height="535px"
            :items="customizeFolders"
            item-key="id"
            no-results-text="Không tìm thấy"
            :search="search"
            show-select
          >
            <template v-slot:[`item.shared_url`]="{ item }">
              <a
                class="primary--text text-decoration-underline"
                :href="
                  `${LIST_MODEL_SITES[homeSetting.SITE].url}/${
                    item.shared_url
                  }-fd`
                "
                target="_blank"
                @click.stop
              >
                {{ item.shared_url }}
              </a>
            </template></v-data-table
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES } from "@/core/constant";
import { FOLDER_HEADER } from "@/modules/Website/constants";

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      ARRAY_LIST_MODEL_SITES: ARRAY_LIST_MODEL_SITES,
      FOLDER_HEADER: FOLDER_HEADER,
      LIST_MODEL_SITES: LIST_MODEL_SITES,
      search: "",
      selectedFolders: [],
      visible: false
    };
  },

  computed: {
    customizeFolders() {
      return this.$store.getters["CUSTOMIZE_FOLDER/customizeFolders"];
    },

    homeSetting() {
      return this.$store.getters["HOME_SETTING/homeSetting"];
    }
  },

  methods: {
    handleSave() {
      const selectedFolderIds = this.selectedFolders.map(folder => folder.id);
      this.$emit("input", selectedFolderIds);
      this.visible = false;
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.selectedFolders = this.value.map(id => {
          return { id: id };
        });
      }
    }
  }
};
</script>

<style scoped>
.checkbox-all {
  margin-left: 38px;
  margin-bottom: 10px;
}

.checkbox-label {
  font-size: 16px;
}
</style>
