<template>
  <div>
    <v-btn color="primary" outlined @click="visible = true">
      <v-icon left>mdi-plus</v-icon>
      Chọn danh mục</v-btn
    >

    <v-dialog v-model="visible" max-width="600px">
      <v-card>
        <v-card-title>
          <div class="font-weight-bold">
            Chọn danh mục
          </div>
          <v-spacer></v-spacer>

          <v-btn
            class="mr-3"
            color="primary"
            depressed
            outlined
            @click="visible = false"
            >Thoát</v-btn
          >

          <v-btn depressed color="primary" @click="handleSave">Lưu</v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <div class="pa-4">
          <v-checkbox
            v-model="isSelectedAll"
            :indeterminate="isIndeterminate"
            label="Chọn tất cả"
            class="mt-0 checkbox-all"
            hide-details
            @change="handleChangeSelectedAlll"
          >
            <template v-slot:label>
              <div class="checkbox-label">
                Chọn tất cả
              </div>
            </template>
          </v-checkbox>

          <div class="treeview-wrapper">
            <v-treeview
              v-model="selectedCategoryIds"
              selectable
              selected-color="primary"
              :items="categoryTrees"
              selection-type="independent"
            >
              <template v-slot:label="{ item }">
                <div>{{ item.name }}</div>
              </template>
            </v-treeview>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array
    }
  },
  data() {
    return {
      isSelectedAll: false,
      visible: false,
      selectedCategoryIds: [],

      // specify selection-type as any string that isn't 'leaf' or 'independent' to get both child and parrent id :D, god damn documentation
      selectionType: ":D thinkpro vip"
    };
  },

  computed: {
    categories() {
      return this.$store.getters["PRODUCT_TYPE/categories"];
    },

    categoryTrees() {
      return this.$store.getters["PRODUCT_TYPE/categoryTrees"];
    },

    isIndeterminate() {
      return (
        this.selectedCategoryIds.length > 0 &&
        this.selectedCategoryIds.length < this.categories.length
      );
    }
  },

  methods: {
    handleSave() {
      this.$emit("input", this.selectedCategoryIds);
      this.visible = false;
    },

    handleChangeSelectedAlll(value) {
      this.selectedCategoryIds = value
        ? this.categories.map(item => item.id)
        : [];
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.selectedCategoryIds = this.value;
      }
    },

    selectedCategoryIds(val) {
      this.isSelectedAll = val.length === this.categories.length;
    }
  }
};
</script>

<style scoped>
.checkbox-all {
  margin-left: 38px;
  margin-bottom: 10px;
}

.checkbox-label {
  font-size: 16px !important;
}

.treeview-wrapper {
  height: 520px;
  overflow: auto;
}
</style>
