<template>
  <div class="home-article-folder pa-4">
    <tp-text-field
      v-model="config.title"
      class="mb-2"
      hide-details="auto"
      label="Tiêu đề"
      placeholder="Nhập tiêu đề ngắn gọn"
    ></tp-text-field>

    <div class="mt-6">
      <button-select-article-folder
        @on-add="onAdd"
      ></button-select-article-folder>
    </div>

    <div class="mt-6">
      <draggable v-model="config.items" :animation="200" class="row">
        <v-col
          v-for="(item, index) in config.items"
          :key="index"
          cols="12"
          sm="3"
        >
          <v-card class="h-full">
            <v-img :src="getSrc(item.image)" alt=""></v-img>

            <v-card-title>{{ getFolderName(item.id) }}</v-card-title>

            <v-card-actions>
              <v-btn
                color="deep-purple lighten-2"
                text
                @click="onRemove(index)"
              >
                Xoá
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ButtonSelectArticleFolder from "./ButtonSelectArticleFolder.vue";

export default {
  components: { draggable, ButtonSelectArticleFolder },

  created() {
    this.$store.dispatch("POST_CATEGORY/getAllPostCategories");
  },

  data() {
    return {};
  },

  methods: {
    onAdd(state) {
      this.config.items.push(state);
    },
    onRemove(index) {
      this.config.items.splice(index, 1);
    },
    getSrc(image) {
      if (typeof image === "string") {
        return image;
      }

      return URL.createObjectURL(image);
    },
    getFolderName(id) {
      return this.allPostCategories.find(item => item.id === id)?.name;
    }
  },

  computed: {
    allPostCategories() {
      return this.$store.getters["POST_CATEGORY/allPostCategories"];
    },
    config() {
      const { ARTICLE_FOLDER } = this.$store.getters[
        "HOME_SETTING/homeSetting"
      ];

      return ARTICLE_FOLDER;
    }
  }
};
</script>

<style>
.home-article-folder {
}
</style>
