<template>
  <div class="pa-4">
    <v-checkbox
      v-model="config.is_show_frame"
      class="mt-0 mb-3"
      hide-details
      label="Hiển thị frame"
    ></v-checkbox>

    <div>
      <div class="mb-2">Frame</div>

      <tp-input-image
        :acceptedFileTypes="['image/*']"
        :maxFileSize="500 * 1024"
        :src.sync="config.frame_image"
        allowCompress
        allowFileSize
        class="flex-grow-1"
        required
        style="height: 300px; max-width: 300px; width: 100%"
      ></tp-input-image>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { createVuexHelpers } from "@/core/composables";

export default defineComponent({
  setup() {
    const { useGetters } = createVuexHelpers();
    const { homeSetting } = useGetters("HOME_SETTING", ["homeSetting"]);

    const config = computed(() => homeSetting.value.FLASH_SALE);

    return {
      config
    };
  }
});
</script>
