<template>
  <div class="pa-4">
    <tp-text-field
      v-model="homeSettingCategory.title"
      class="mb-4"
      dense
      hide-details="auto"
      label="Tên danh mục"
      outlined
      placeholder="Nhập nội dung"
      validate="required"
    ></tp-text-field>

    <tp-select
      v-model="homeSettingCategory.type"
      :items="categoryTypes"
      class="mb-4"
      dense
      hide-details="auto"
      item-id="value"
      item-text="label"
      outlined
    ></tp-select>

    <button-select-category
      v-model="homeSettingCategory.ids"
    ></button-select-category>

    <draggable-list
      v-model="homeSettingCategory.ids"
      :base-value="categories"
      is-category-list
    ></draggable-list>
  </div>
</template>

<script>
import ButtonSelectCategory from "./ButtonSelectCategory.vue";
import DraggableList from "./DraggableList.vue";

export default {
  components: {
    ButtonSelectCategory,
    DraggableList
  },
  data() {
    return {
      categoryTypes: [
        { value: "slide", label: "Slider" },
        { value: "expand", label: "Expand" }
      ]
    };
  },

  computed: {
    categories() {
      return this.$store.getters["PRODUCT_TYPE/categories"];
    },

    homeSettingCategory() {
      const { CATEGORY } = this.$store.getters["HOME_SETTING/homeSetting"];
      return CATEGORY;
    }
  }
};
</script>

<style></style>
