<template>
  <v-card class="pa-4 overflow-y-auto" flat>
    <div>
      <div class="font-weight-bold mb-1">Website</div>

      <tp-select
        v-model="homeSetting.SITE"
        :items="ARRAY_LIST_MODEL_SITES"
        item-text="text"
        item-value="id"
        placeholder="Chọn website"
        validate="required"
        @change="handleChangeSite($event)"
      ></tp-select>
    </div>

    <v-divider class="mb-4"></v-divider>

    <div class="font-weight-bold">Section</div>

    <v-list>
      <v-list-item-group v-model="internalActiveTab" color="primary" mandatory>
        <v-list-item v-for="section in HOME_SECTIONS" :key="section.id">
          <v-list-item-content>
            <v-list-item-title>{{ section.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES } from "@/core/constant";
import { HOME_SECTIONS } from "@/modules/Website/constants";
export default {
  props: {
    value: {
      type: Number
    }
  },

  data() {
    return {
      ARRAY_LIST_MODEL_SITES: ARRAY_LIST_MODEL_SITES,
      HOME_SECTIONS: HOME_SECTIONS,
      selectedItem: 0
    };
  },

  computed: {
    homeSetting() {
      return this.$store.getters["HOME_SETTING/homeSetting"];
    },

    internalActiveTab: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },

  created() {
    this.handleChangeSite(this.homeSetting.SITE);
  },

  methods: {
    handleChangeSite(site) {
      this.$store.dispatch(
        "HOME_SETTING/getHomeSettingBySite",
        this.homeSetting.SITE
      );

      this.$store.dispatch("CUSTOMIZE_FOLDER/getCustomizeFolders", {
        filter: {
          site: site
        },
        cur_page: 1,
        per_page: 1000,
        search: null
      });
    }
  }
};
</script>
<style></style>
