<template>
  <v-card flat>
    <div class="pa-4 d-flex justify-space-between">
      <div class="font-weight-bold">{{ HOME_SECTIONS[activeTab].name }}</div>

      <div>
        <v-btn
          :loading="statusRequest.value === 'loading-updateHomeSetting'"
          color="primary"
          depressed
          @click="$emit('onUpdate')"
          >Áp dụng
        </v-btn>
      </div>
    </div>

    <div class="scrollable">
      <div class="mt-4 section-container">
        <component
          :is="HOME_SECTIONS[activeTab].componentName"
          class="tab"
        ></component>
      </div>
    </div>
  </v-card>
</template>

<script>
import { HOME_SECTIONS } from "@/modules/Website/constants";
import HomeBanner from "./components/HomeBanner.vue";
import HomeCategory from "./components/HomeCategory.vue";
import HomeFolder from "./components/HomeFolder.vue";
import HomePromotion from "./components/HomePromotion.vue";
import HomeFlashSale from "./components/HomeFlashSale.vue";
import HomeArticleFolder from "./components/HomeArticleFolder.vue";
import HomeOutstandingProduct from "./components/HomeOutstandingProduct.vue";
import HomeFeedback from "./components/HomeFeedback.vue";
import HomeTopKeywords from "./components/HomeTopKeywords.vue";
import NewsTopKeywords from "./components/NewsTopKeywords.vue";

export default {
  props: {
    activeTab: {
      type: Number
    }
  },

  components: {
    HomeBanner,
    HomeCategory,
    HomeFolder,
    HomePromotion,
    HomeFlashSale,
    HomeArticleFolder,
    HomeOutstandingProduct,
    HomeFeedback,
    HomeTopKeywords,
    NewsTopKeywords
  },

  data() {
    return {
      HOME_SECTIONS
    };
  },

  computed: {
    statusRequest() {
      return this.$store.getters["HOME_SETTING/statusRequest"];
    }
  }
};
</script>

<style scoped>
.section-container {
  max-width: 1300px;
  margin: 0 auto;
}

.scrollable {
  height: calc(100% - 85px);
  overflow: auto;
}
</style>
