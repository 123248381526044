<template>
  <div class="pa-4">
    <v-overlay
      v-show="promotionStatusRequest.value === 'loading-getPromotions'"
      absolute
      opacity="0.5"
    >
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <tp-text-field
      v-model="homeSettingPromotion.title"
      class="mb-4"
      dense
      hide-details="auto"
      label="Tiêu đề"
      outlined
      placeholder="Nhập nội dung ngắn gọn"
      validate="required"
    ></tp-text-field>

    <button-select-promotion
      v-model="homeSettingPromotion.ids"
    ></button-select-promotion>

    <draggable-list
      v-model="homeSettingPromotion.ids"
      :base-value="promotions"
      is-promotion-list
    ></draggable-list>
  </div>
</template>

<script>
import ButtonSelectPromotion from "./ButtonSelectPromotion.vue";
import DraggableList from "./DraggableList.vue";

export default {
  components: {
    ButtonSelectPromotion,
    DraggableList
  },
  computed: {
    promotions() {
      return this.$store.getters["PROMOTION/promotions"];
    },

    promotionStatusRequest() {
      return this.$store.getters["PROMOTION/statusRequest"];
    },

    homeSettingPromotion() {
      const { PROMOTION } = this.$store.getters["HOME_SETTING/homeSetting"];
      return PROMOTION;
    }
  }
};
</script>

<style></style>
