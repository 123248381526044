<template>
  <div>
    <v-btn color="primary" outlined @click="open">
      <v-icon left>mdi-plus</v-icon>
      Chọn sản phẩm
    </v-btn>

    <v-dialog v-model="visible" max-width="768px">
      <v-card>
        <v-card-title>
          <div class="font-weight-bold">
            Chọn sản phẩm
          </div>

          <v-spacer></v-spacer>

          <v-btn class="mr-3" color="primary" depressed outlined @click="close">
            Thoát
          </v-btn>

          <v-btn color="primary" depressed @click="onAdd">Lưu</v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <div class="pa-4">
          <tp-autocomplete
            v-model="state.id"
            :items="filteredProductItems"
            :search-input.sync="searchKey"
            class="tp-filter-autocomplete mt-4"
            clearable
            item-text="name"
            item-value="id"
            no-data-text="Không có dữ liệu"
            placeholder="Tìm kiếm sản phẩm"
            single-line
          ></tp-autocomplete>

          <div class="mt-6">
            <tp-input-image
              :src.sync="state.image"
              allow-compare-image
              allow-compress
              allow-file-size
              allow-file-type-validation
              style="height: 300px; max-width: 300px; width: 100%"
            ></tp-input-image>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { useVisible } from "@thinkvn/composables/lib/useVisible";
import { TUploadImage } from "@vt7/upload-image";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import { debouncedWatch } from "@vueuse/core";
import ProductService from "@/modules/Goods/service/product.service";

export default defineComponent({
  components: { TUploadImage },

  setup(_, { emit }) {
    const { searchSpecs } = ProductService;
    const { visible, open, close } = useVisible();

    const state = reactive({
      id: null,
      image: null
    });
    const searchKey = ref("");

    const { data: filteredProductItems, fetch } = useFetchData(
      () => searchSpecs(searchKey.value),
      {
        enabled: () => Boolean(searchKey.value),
        transform: response => response.data
      }
    );

    debouncedWatch(searchKey, fetch, { debounce: 300 });

    const onAdd = () => {
      const item = filteredProductItems.value.find(
        item => item.id === state.id
      );

      emit("on-add", { ...item, ...state });

      state.id = null;
      state.image = null;
      close();
    };

    return {
      visible,
      open,
      close,
      state,
      searchKey,
      filteredProductItems,
      onAdd
    };
  },

  data() {
    return {
      apiKeys: process.env.VUE_APP_COMPRESS_API_KEYS.split(",")
    };
  }
});
</script>

<style scoped></style>
