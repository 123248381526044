<template>
  <div>
    <v-btn color="primary" outlined @click="visible = true">
      <v-icon left>mdi-plus</v-icon>
      Chọn khuyến mại</v-btn
    >

    <v-dialog v-model="visible" max-width="768px">
      <v-card>
        <v-card-title>
          <div class="font-weight-bold">
            Chọn khuyến mại
          </div>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-3"
            depressed
            color="primary"
            outlined
            @click="visible = false"
            >Thoát</v-btn
          >

          <v-btn depressed color="primary" @click="handleSave">Lưu</v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <div class="pa-4">
          <v-text-field
            v-model="search"
            class="mb-3"
            append-icon="mdi-magnify"
            label="Tìm kiếm theo tên khuyến mại"
            outlined
            dense
            hide-details
          ></v-text-field>

          <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
            Chọn tối đa
            <span class="font-weight-bold">10 khuyến mại</span>
          </div>

          <v-data-table
            v-model="selectedPromotion"
            checkbox-color="primary"
            fixed-header
            :headers="PROMOTION_HEADER"
            height="535px"
            :items="promotions"
            item-key="id"
            no-results-text="Không tìm thấy"
            :search="search"
            show-select
          >
            <template v-slot:[`item.slug`]="{ item }">
              <a
                class="primary--text text-decoration-underline"
                :href="
                  `${LIST_MODEL_SITES[homeSetting.SITE].url}/khuyen-mai/${
                    item.slug
                  }`
                "
                target="_blank"
                @click.stop
              >
                {{ item.slug }}
              </a>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES } from "@/core/constant";
import { PROMOTION_HEADER } from "@/modules/Website/constants";

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      ARRAY_LIST_MODEL_SITES: ARRAY_LIST_MODEL_SITES,
      PROMOTION_HEADER: PROMOTION_HEADER,
      LIST_MODEL_SITES: LIST_MODEL_SITES,
      search: "",
      selectedPromotion: [],
      visible: false
    };
  },

  computed: {
    promotions() {
      return this.$store.getters["PROMOTION/promotions"];
    },

    homeSetting() {
      return this.$store.getters["HOME_SETTING/homeSetting"];
    }
  },

  methods: {
    handleSave() {
      const selectedPromotionIds = this.selectedPromotion.map(
        promotion => promotion.id
      );
      this.$emit("input", selectedPromotionIds);
      this.visible = false;
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.selectedPromotion = this.value.map(id => {
          return { id: id };
        });
      }
    },

    selectedPromotion(val, oldVal) {
      if (val.length > 10) {
        // allow select only 10 promotions
        this.$nextTick(() => {
          this.selectedPromotion = oldVal;
        });
      }
    }
  }
};
</script>

<style scoped>
.checkbox-all {
  margin-left: 38px;
  margin-bottom: 10px;
}

.checkbox-label {
  font-size: 16px;
}
</style>
