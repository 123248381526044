<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="4">
        <v-btn depressed block large @click="handleCreateMode">
          <v-icon size="26px" class="grey--text text--darken-1"
            >mdi-plus</v-icon
          >
        </v-btn>

        <div
          v-if="!countItem"
          class="d-flex justify-center align-center py-5 mt-4 grey--text text--darken-1"
          style="font-size: 18px;"
        >
          Chưa có từ khóa nào
        </div>

        <div v-else class="d-flex flex-wrap ml-n2 mt-2">
          <div
            class="ma-2"
            v-for="(item, index) in newsSearch.items"
            :key="index"
          >
            <div
              v-bind:class="{
                'blue--text transparent': currentKeywordIndex === index
              }"
              class="align-center card-draggable d-flex grey justify-center lighten-2 px-3 rounded-lg text-body-1"
              @drop="dragDrop($event, item, index)"
              @dragstart="dragStart($event, item, index)"
              @dragover="dragOver"
              @dragleave="dragLeave"
              @dragenter="dragEnter"
              @click="handleSelectKeyword(index)"
              :draggable="true"
              style="height: 32px; border-color: #0065ee !important;"
            >
              <span>{{ item.text }}</span>

              <v-icon
                v-bind:class="{ 'blue--text': currentKeywordIndex === index }"
                style="margin-left: 6px; cursor: pointer;"
                size="18px"
                @click="handleRemoveKeyword(index)"
                >mdi-close</v-icon
              >
            </div>
          </div>
        </div>
      </v-col>

      <v-divider style="margin:0 10px;" vertical></v-divider>

      <v-col>
        <v-form ref="form"
          ><div class="w-full">
            <div class="font-weight-medium mb-2">
              Tên từ khóa
            </div>

            <v-text-field
              v-model="state.text"
              :rules="[v => !!v || 'Không được bỏ trống']"
              background-color="white"
              class="text-body-1"
              dense
              filled
              outlined
              placeholder="Nhập từ khóa"
              required
              single-line
              style="width:339px;"
            ></v-text-field>
          </div>

          <div class="w-full">
            <div class="font-weight-medium mb-2">Đường dẫn</div>

            <div class="d-flex justify-between">
              <v-text-field
                v-model="state.url"
                background-color="white"
                class="text-body-1"
                dense
                filled
                full-width
                outlined
                placeholder="Nhập đường dẫn"
                single-line
                :rules="[v => !!v || 'Không được bỏ trống']"
                required
              ></v-text-field>

              <div class="ml-3 d-flex align-center" style="height:40px;">
                <v-btn
                  v-if="!createMode"
                  :href="state.url"
                  color="primary"
                  target="_blank"
                  text
                  x-small
                >
                  Truy cập
                </v-btn>
              </div>
            </div>
          </div>

          <v-btn
            v-if="createMode"
            class="blue lighten-4"
            color="primary"
            depressed
            large
            plain
            @click="addItemSearch"
          >
            Thêm
          </v-btn>

          <v-btn
            class="blue lighten-4"
            color="primary"
            depressed
            large
            plain
            v-else
            @click="handleUpdateKeyword"
          >
            Cập nhật
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createVuexHelpers } from "@/core/composables";
import { computed, defineComponent, reactive, ref, watch } from "vue";
export default defineComponent({
  setup() {
    const { useGetters } = createVuexHelpers();

    const state = reactive({
      text: "",
      url: ""
    });
    const form = ref();
    const createMode = ref(true);
    const currentKeywordIndex = ref();
    const draggableItem = ref(null);
    const draggableItemIndex = ref(null);

    const { homeSetting } = useGetters("HOME_SETTING", ["homeSetting"]);

    const newsSearch = computed(() => {
      return homeSetting.value.NEWS_TOP_KEYWORDS;
    });

    const newsSearchItems = computed(() => newsSearch.value.items);

    const countItem = computed(() => newsSearchItems.value.length);

    const addItemSearch = () => {
      const isValid = form.value.validate();

      if (!isValid) return;

      newsSearchItems.value.push({ text: state.text, url: state.url });

      resetState();
    };

    const resetState = () => {
      form.value.reset();
      currentKeywordIndex.value = undefined;
    };

    const handleCreateMode = () => {
      createMode.value = true;
      resetState();
    };

    const handleSelectKeyword = index => {
      createMode.value = false;
      currentKeywordIndex.value = index;

      state.text = newsSearchItems.value[index].text;
      state.url = newsSearchItems.value[index].url;
    };

    const handleUpdateKeyword = () => {
      const isValid = form.value.validate();

      if (!isValid) return;

      newsSearchItems.value[currentKeywordIndex.value].text = state.text;
      newsSearchItems.value[currentKeywordIndex.value].url = state.url;
    };

    const handleRemoveKeyword = index => {
      newsSearchItems.value.splice(index, 1);
    };

    watch(
      () => homeSetting.value.SITE,
      () => {
        resetState();
      }
    );

    const dragStart = (event, item, index) => {
      resetState();
      draggableItem.value = item;
      draggableItemIndex.value = index;
    };

    const dragOver = event => {
      event.preventDefault();
    };

    const dragEnter = event => {
      event.preventDefault();

      if (event.target.className.includes("card-draggable")) {
        event.target.style.outline = "1px solid #0065ee";
      }
    };

    const dragLeave = event => {
      console.log(event);
      event.target.style.outline = null;
    };

    const dragDrop = (event, item, index) => {
      console.log(event);
      event.target.style.outline = null;

      const arr = newsSearchItems.value;

      arr.splice(draggableItemIndex.value, 1);
      arr.splice(index, 0, draggableItem.value);
    };

    return {
      addItemSearch,
      countItem,
      createMode,
      currentKeywordIndex,
      dragDrop,
      dragEnter,
      draggableItem,
      draggableItemIndex,
      dragLeave,
      dragOver,
      dragStart,
      form,
      handleCreateMode,
      handleRemoveKeyword,
      handleSelectKeyword,
      handleUpdateKeyword,
      newsSearch,
      homeSetting,
      state
    };
  }
});
</script>

<style lang="scss" scoped>
.card-draggable {
  cursor: move;
}
.hold {
  opacity: 0.5;
  background: red;
}
</style>
