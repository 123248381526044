<template>
  <div>
    <div class="font-weight-medium mt-4">
      Đã chọn ({{ value ? value.length : 0 }})
    </div>
    <v-row class="px-1">
      <v-col
        class="px-2 py-2"
        cols="12"
        lg="12"
        v-for="(id, index) in value"
        :key="index"
      >
        <div
          class="card-draggable text-body-1 d-flex align-center rounded px-3 py-2"
          draggable="true"
          style="height: 100%"
          @dragstart="dragStart($event, id, index)"
          @dragover="dragOver"
          @dragenter="dragEnter"
          @dragleave="dragLeave"
          @dragend="dragEnd"
          @drop="dragDrop($event, id, index)"
          @mouseover="hoverId = id"
          @mouseleave="hoverId = null"
        >
          <div v-if="isCategoryList" class="pointer-event--none flex-grow-1">
            <div>{{ getItemById(id).name }}</div>
          </div>

          <div v-if="isFolderList" class="flex-grow-1">
            <a
              class="text-decoration-underline"
              :href="folderUrl(id)"
              target="_blank"
              >{{ getItemById(id).name }}</a
            >
          </div>

          <div v-if="isPromotionList" class="flex-grow-1">
            <a
              class="text-decoration-underline"
              :href="promotionUrl(id)"
              target="_blank"
              >{{ getItemById(id).name }}</a
            >
          </div>

          <div class="d-flex align-center">
            <icon-drag v-if="hoverId === id"></icon-drag>

            <v-btn icon small @click="removeSelectedProductOption(index)">
              <v-icon color="black" size="20px">mdi-delete-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IconDrag from "@/core/assets/icons/IconDrag.vue";

export default {
  components: {
    IconDrag
  },

  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },

    isCategoryList: {
      type: Boolean,
      default: false
    },

    isFolderList: {
      type: Boolean,
      default: false
    },

    isPromotionList: {
      type: Boolean,
      default: false
    },

    baseValue: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      draggableItem: null,
      draggableItemIndex: null,
      hoverId: false,
      CMS_BASE_URL: process.env.VUE_APP_BASE_URL
    };
  },

  methods: {
    dragStart(event, item, index) {
      event.target.classname += " hold";

      this.draggableItem = item;
      this.draggableItemIndex = index;

      setTimeout(() => {
        event.target.className += " invisible";
      }, 0);
    },
    dragEnd(event) {
      event.target.className =
        "card-draggable text-body-1 d-flex align-center rounded px-3 py-2";
    },

    dragOver(event) {
      event.preventDefault();
    },

    dragEnter(event) {
      event.preventDefault();

      if (event.target.className.includes("card-draggable")) {
        event.target.style.border = "1px solid #0065ee";
      }
    },

    dragLeave(event) {
      event.target.style.border = null;
    },

    async dragDrop(event, item, index) {
      event.target.style.border = null;

      const arr = this.value;

      arr.splice(this.draggableItemIndex, 1);
      arr.splice(index, 0, this.draggableItem);

      this.$emit("input", arr);
    },

    folderUrl(id) {
      return `${this.CMS_BASE_URL}/#/website/customize-folder/detail/${id}`;
    },

    promotionUrl(id) {
      return `${this.CMS_BASE_URL}/#/promotion/detail/${id}`;
    },

    removeSelectedProductOption(index) {
      const arr = this.value;

      arr.splice(index, 1);
      this.$emit("input", arr);
    },

    getItemById(id) {
      return this.baseValue.find(item => item.id === id);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-subtitle {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0 !important;
  }
}

.card-draggable {
  border: 1px solid rgba(0, 0, 0, 0.12);
  cursor: move;
}

.hold {
  opacity: 0.5;
}

.invisible {
  opacity: 0.5;
}

.pointer-event--none {
  pointer-events: none;
}
</style>
